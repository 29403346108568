$switch-unit:                     px;
$switch-width:                    68;
$switch-width-status:             88;
$switch-height:                   32; // The default Bootstrap btn height in px
$switch-font-size:                12;
$switch-font-family:              $font-family-sans-serif;
$switch-duration:                 300ms;

$switch-checked-color:            $black;
$switch-checked-bg:               $green;
$switch-checked-text-io:          "I";
$switch-checked-text-onoff:       "Present";
$switch-checked-text-yesno:       "Yes";
$switch-checked-text-status:      "Enabled";

$switch-unchecked-color:          $black;
$switch-unchecked-bg:             $gray-400;
$switch-unchecked-text-io:        "O";
$switch-unchecked-text-onoff:     "Away";
$switch-unchecked-text-yesno:     "No";
$switch-unchecked-text-status:    "Disabled";
