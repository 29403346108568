.custom-switch {
  .custom-switch-input {
    display: none;
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    & + .custom-switch-btn {
      box-sizing: border-box;
      &:selection {
        background: none;
      }
    }
    + .custom-switch-btn {
      outline: 0;
      display: inline-block;
      position: relative;
      user-select: none;
      cursor: pointer;
      width: ($switch-width + $switch-unit);
      height: ($switch-height + $switch-unit);
      margin: 0;
      padding: (($switch-height / 9.5) + $switch-unit);
      background: $switch-unchecked-bg;
      border-radius: (($switch-height * 2) + $switch-unit);
      transition: all $switch-duration ease;
      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: (($switch-height - 8) + $switch-unit);
        height: (($switch-height - 8) + $switch-unit);
      }
      &:after {
        left: 2px;
        border-radius: 50%;
        background: white;
        transition: all $switch-duration ease;
      }
      &:before {
        display: none;
      }
    }
    &:checked + .custom-switch-btn {
      background: $switch-checked-bg;
      &:after {
        left: (($switch-width - $switch-height) + $switch-unit);
      }
      ~ .custom-switch-content-checked {
        opacity: 1;
        height: auto;
      }
      ~ .custom-switch-content-unchecked {
        display: none;
        opacity: 0;
        height: 0;
      }
    }
    &:not(:checked) + .custom-switch-btn {
      ~ .custom-switch-content-checked {
        display: none;
        opacity: 0;
        height: 0;
      }
      ~ .custom-switch-content-unchecked {
        opacity: 1;
        height: auto;
      }
    }
  }
  .custom-switch-form-text {
    display: inline-block;
    height: ($switch-height + $switch-unit);
    margin-left: .5rem;
    line-height: ($switch-height + $switch-unit);
    vertical-align: top;
  }
  &.custom-switch-label-io {
    .custom-switch-input {
      + .custom-switch-btn {
        background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' width='#{$switch-width}' height='#{$switch-height}'><text x='#{($switch-width / 1.6)}' y='#{($switch-height / 1.6)}' font-size='#{($switch-font-size + $switch-unit)}' font-family='#{$switch-font-family}' fill='#{$switch-unchecked-color}'>#{$switch-unchecked-text-io}</text></svg>");
      }
      &:checked + .custom-switch-btn {
        background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' width='#{$switch-width}' height='#{$switch-height}'><text x='#{($switch-width / 3.75)}' y='#{($switch-height / 1.6)}' font-size='#{($switch-font-size + $switch-unit)}' font-family='#{$switch-font-family}' fill='#{$switch-checked-color}'>#{$switch-checked-text-io}</text></svg>");
      }
    }
  }
  &.custom-switch-label-onoff {
    .custom-switch-input {
      + .custom-switch-btn {
        background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' width='#{$switch-width}' height='#{$switch-height}'><text x='#{($switch-width / 1.75)}' y='#{($switch-height / 1.6)}' font-size='#{($switch-font-size + $switch-unit)}' font-family='#{$switch-font-family}' fill='#{$switch-unchecked-color}'>#{$switch-unchecked-text-onoff}</text></svg>");
      }
      &:checked + .custom-switch-btn {
        background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' width='#{$switch-width}' height='#{$switch-height}'><text x='#{($switch-width / 7)}' y='#{($switch-height / 1.6)}' font-size='#{($switch-font-size + $switch-unit)}' font-family='#{$switch-font-family}' fill='#{$switch-checked-color}'>#{$switch-checked-text-onoff}</text></svg>");
      }
    }
  }
  &.custom-switch-label-yesno {
    .custom-switch-input {
      + .custom-switch-btn {
        background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' width='#{$switch-width}' height='#{$switch-height}'><text x='#{($switch-width / 1.75)}' y='#{($switch-height / 1.6)}' font-size='#{($switch-font-size + $switch-unit)}' font-family='#{$switch-font-family}' fill='#{$switch-unchecked-color}'>#{$switch-unchecked-text-yesno}</text></svg>");
      }
      &:checked + .custom-switch-btn {
        background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' width='#{$switch-width}' height='#{$switch-height}'><text x='#{($switch-width / 7)}' y='#{($switch-height / 1.6)}' font-size='#{($switch-font-size + $switch-unit)}' font-family='#{$switch-font-family}' fill='#{$switch-checked-color}'>#{$switch-checked-text-yesno}</text></svg>");
      }
    }
  }
  &.custom-switch-label-status {
    .custom-switch-input {
      + .custom-switch-btn {
        width: ($switch-width-status + $switch-unit);
        background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' width='#{$switch-width-status}' height='#{$switch-height}'><text x='#{($switch-width / 1.75)}' y='#{($switch-height / 1.6)}' font-size='#{($switch-font-size + $switch-unit)}' font-family='#{$switch-font-family}' fill='#{$switch-unchecked-color}'>#{$switch-unchecked-text-status}</text></svg>");
      }
      &:checked + .custom-switch-btn {
        background-image: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' width='#{$switch-width-status}' height='#{$switch-height}'><text x='#{($switch-width / 7)}' y='#{($switch-height / 1.6)}' font-size='#{($switch-font-size + $switch-unit)}' font-family='#{$switch-font-family}' fill='#{$switch-checked-color}'>#{$switch-checked-text-status}</text></svg>");
        &:after {
          left: (($switch-width-status - $switch-height) + $switch-unit);
        }
      }
    }
  }
}
